import './pins.scss';

export const VelorouteIcon = () => {
    return (<div className="velo-icon">
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" x="0px" y="0px" viewBox="0 0 30 30" xmlSpace="preserve">
        <rect className="bg" width="30" height="30"/>
        <path fill="white" d="M22.2,12.2c-0.7,0-1.4,0.2-2,0.4l-1.1-1.8l0.8-1.5C20,9,20,8.9,19.9,8.7c-0.1-0.2-0.3-0.2-0.4-0.2h-1.8
            c-0.3,0-0.5,0.2-0.5,0.5s0.2,0.5,0.5,0.5h1l-0.5,0.9l-7-0.7l0.3-0.7l1.6,0.2c0.3,0,0.5-0.1,0.6-0.4c0-0.3-0.2-0.5-0.4-0.6l-2-0.3
            c-0.2,0-0.5,0.1-0.5,0.3l-1.6,4.1c-0.4-0.1-0.8-0.2-1.3-0.2c-2.7,0-5,2.2-5,5s2.2,5,5,5c2.7,0,5-2.2,5-5c0-1.9-1.1-3.6-2.8-4.4
            l0.6-1.6l4,6.4c0,0,0,0,0,0c0,0.1,0.1,0.1,0.2,0.1c0,0,0,0,0,0c0,0,0,0,0,0c0.1,0,0.2,0.1,0.2,0.1h2.2c0.3,2.5,2.4,4.5,4.9,4.5
            c2.7,0,5-2.2,5-5S24.9,12.2,22.2,12.2z M11.8,17.1c0,2.2-1.8,4-4,4c-2.2,0-4-1.8-4-4c0-2.2,1.8-4,4-4c0.3,0,0.6,0,0.9,0.1L7.3,17
            c-0.1,0.3,0,0.5,0.3,0.6c0.1,0,0.1,0,0.2,0c0.2,0,0.4-0.1,0.5-0.3l1.4-3.7C10.9,14.3,11.8,15.6,11.8,17.1z M19.3,13.1
            c-1.1,0.8-1.9,2.1-2,3.5h-1.3l2.6-4.9L19.3,13.1z M19.8,14l1.5,2.7h-3.1C18.4,15.5,19,14.6,19.8,14z M17.6,11.3L15,16.1l-3.4-5.4
            L17.6,11.3z M22.2,21.1c-2,0-3.7-1.5-3.9-3.5h3.9c0.2,0,0.3-0.1,0.4-0.2c0.1-0.2,0.1-0.3,0-0.5l-2-3.4c0.5-0.2,1-0.3,1.5-0.3
            c2.2,0,4,1.8,4,4C26.2,19.3,24.4,21.1,22.2,21.1z"/>
    </svg>
</div>)
}