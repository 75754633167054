import './pins.scss';

export const TrainIcon = () => {
    return (<div className="train-icon">
        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 30 30" xmlSpace="preserve">
        <rect className="bg" y="0" width="30" height="30"/>
        <g>
            <path fill="white" d="M22.4,6.5c-0.5-0.5-3-2.2-7.4-2.2S8.2,6.1,7.7,6.5C7.2,7,7.1,7.5,7.1,8.1v11.4c0,0.6,0.3,1.1,0.6,1.6
                c0.3,0.5,0.9,0.6,1.6,0.6h11.4c0.6,0,1.1-0.3,1.6-0.6s0.6-0.9,0.6-1.6V8.1C23,7.5,22.7,7,22.4,6.5z M10.7,19.2
                c-0.8,0-1.4-0.6-1.4-1.4c0-0.8,0.6-1.4,1.4-1.4s1.4,0.6,1.4,1.4C12.1,18.6,11.5,19.2,10.7,19.2z M19.4,19.2c-0.8,0-1.4-0.6-1.4-1.4
                c0-0.8,0.6-1.4,1.4-1.4c0.8,0,1.4,0.6,1.4,1.4C20.9,18.6,20.1,19.2,19.4,19.2z M21.5,13c0,0.3-0.3,0.6-0.6,0.6H9.3
                c-0.3,0-0.6-0.3-0.6-0.6V8.1c0-0.2,0.2-0.5,0.3-0.5c0.8-0.5,3-1.7,6.1-1.7s5.3,1.3,6.1,1.7c0.2,0,0.3,0.3,0.3,0.5V13z"/>
            <polygon fill="white" points="9.6,25.7 11.1,25.7 12.7,22.7 11.1,22.7 	"/>
            <polygon fill="white" points="17.4,22.7 19,25.7 20.5,25.7 19,22.7 	"/>
        </g>
        </svg>
    </div>)
}