import './Logo.scss';

export const Logo = () => {
    
    return (<svg id="logo" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 216.2 41" style={{ enableBackground: 'new 0 0 216.2 41' }} xmlSpace="preserve">
<g>
	<path d="M25,23.4c-0.5,4.1-1.7,7.3-3.6,9.7s-4.5,3.5-7.6,3.4c-2.5-0.1-4.4-1.1-5.8-3.1L7,36H0L6.3,0H14l-2.5,12.7
		c1.7-1.7,3.6-2.6,5.7-2.6c2.5,0,4.4,0.9,5.8,2.7s2.1,4.1,2.2,7.1c0,1,0,2-0.1,2.9L25,23.4z M17.3,22.9c0.1-1,0.2-1.9,0.2-2.7
		c0-2.5-0.9-3.8-2.7-3.9c-1.6-0.1-2.9,0.6-4,2L9,28.4c0.5,1.3,1.5,2,3.2,2c2.5,0.1,4.1-1.6,4.8-4.9l0.3-2.1L17.3,22.9z"/>
	<path d="M40.7,36c-0.2-0.6-0.3-1.4-0.3-2.3c-1.7,1.9-3.8,2.8-6.2,2.8c-2.2,0-4-0.8-5.5-2.2s-2.1-3.2-2-5.2c0.1-2.8,1.2-4.9,3.4-6.3
		s5.2-2.2,9.1-2.2l2.6,0l0.3-1.4l0.1-1.2c-0.1-1.4-0.8-2.2-2.1-2.2c-1.7,0-2.7,0.9-3.1,2.8l-7.7,0c0.1-1.7,0.6-3.2,1.7-4.4
		s2.4-2.2,4.2-2.9s3.7-1,5.7-0.9c1.9,0,3.6,0.4,5.1,1.2s2.5,1.8,3.2,3.2s1,2.9,0.8,4.7l-1.9,11.9l-0.1,1.5c-0.1,1.2,0.1,2.1,0.4,3
		l0,0.4H40.7z M36.3,30.9c1.6,0.1,3-0.6,4.1-2.1l0.8-4.1l-2,0c-1.2,0-2.3,0.4-3.2,1.1s-1.4,1.6-1.6,2.8c-0.1,0.6,0,1.2,0.4,1.6
		S35.6,30.9,36.3,30.9z"/>
	<path d="M62.5,13.4c1.9-2.2,4.1-3.3,6.5-3.3c2.5,0.1,4.4,1,5.7,2.8s1.7,4.2,1.3,7.3L73.4,36h-7.7l2.6-15.9c0.1-0.5,0.1-1,0-1.5
		c-0.1-1.5-1-2.3-2.6-2.4c-1.4,0-2.6,0.5-3.7,1.5L58.9,36h-7.7l6.3-36h7.7L62.5,13.4z"/>
	<path d="M89.3,10.6l-0.5,3c2-2.4,4.5-3.6,7.3-3.5c2.3,0,4.1,0.9,5.2,2.6s1.6,4.1,1.3,7.3l-2.7,16h-7.7l2.7-16c0.1-0.5,0.1-1,0-1.5
		c-0.1-1.5-1-2.2-2.6-2.2c-1.4,0-2.6,0.6-3.7,1.8l-3.2,18h-7.7l4.4-25.4L89.3,10.6z"/>
	<path d="M159.1,23.4c-0.3,2.6-1,4.9-2.1,7s-2.5,3.6-4,4.6s-3.3,1.5-5.2,1.4c-3.1-0.1-5.3-1.2-6.8-3.4l-0.9,3h-3.9l6.3-36h4.2
		l-2.6,13.5c2-2.2,4.5-3.3,7.4-3.3c2.4,0.1,4.3,1,5.7,2.7s2.1,4.1,2.2,7.2c0,1,0,2-0.1,2.9L159.1,23.4z M155.1,21
		c0-2.3-0.3-4-1.1-5.2c-0.8-1.2-2.1-1.8-3.7-1.9c-2.6-0.1-4.9,1.3-6.7,4l-1.9,11c0.9,2.6,2.7,3.9,5.3,4c1.7,0.1,3.1-0.4,4.3-1.5
		s2.2-2.5,2.8-4.4C154.7,25.1,155,23.1,155.1,21z"/>
	<path d="M166.6,36h-4.2l4.4-25.4h4.2L166.6,36z M170.1,1.4c0.7,0,1.3,0.2,1.8,0.6s0.7,1.1,0.7,1.8c0,0.7-0.3,1.3-0.7,1.8
		s-1.1,0.7-1.8,0.8s-1.3-0.2-1.8-0.6s-0.7-1-0.7-1.8s0.2-1.3,0.7-1.8S169.3,1.4,170.1,1.4z"/>
	<path d="M181.4,24.3l-3.3,3l-1.5,8.7h-4.2l6.3-36h4.2l-3.8,21.5l2.6-2.6l9-8.2h5.5l-11.6,10.8l8,14.6h-4.6L181.4,24.3z"/>
	<path d="M204.7,36.5c-3.2-0.1-5.6-1.2-7.4-3.5s-2.5-5.2-2.2-8.7l0.1-1c0.3-2.5,1-4.8,2.2-6.9s2.7-3.6,4.5-4.7s3.7-1.6,5.7-1.5
		c2.6,0.1,4.7,0.9,6.1,2.6s2.3,3.9,2.4,6.8c0,1,0,2-0.1,3l-0.3,1.9h-16.6c-0.3,2.3,0.1,4.2,1.1,5.9s2.6,2.4,4.7,2.5
		c2.5,0.1,4.8-1.1,6.9-3.4l2.4,2.1c-1.1,1.5-2.4,2.7-4.1,3.6S206.8,36.5,204.7,36.5z M207.4,13.7c-1.8,0-3.3,0.5-4.6,1.8
		s-2.3,3.1-3.1,5.5l12.3,0l0.1-0.4c0.2-2-0.1-3.6-0.9-4.9S209.1,13.8,207.4,13.7z"/>
</g>
<g>
	<path d="M133.1,19.4l-0.1,0.4c-1,3.7-1.9,7.1-4.3,10.2c0.4,0.3,0.8,0.6,1.1,1.1c2.7-3.4,3.7-7,4.7-10.8l0.1-0.4L133.1,19.4z"/>
	<path d="M126.9,30.5c-1.3,0-2.4,1.1-2.4,2.4s1.1,2.4,2.4,2.4s2.4-1.1,2.4-2.4S128.2,30.5,126.9,30.5z"/>
</g>
<g>
	<g>
		<path d="M119,17.7c5.2-3.6,10.1-7,10.3-11.9c0.1-3.1-2.3-5.7-5.6-6.2c-2.6-0.4-5.9,0.6-8,4.2c0.5,0.1,1,0.3,1.4,0.5
			c1.7-2.8,4.3-3.6,6.4-3.3c2.2,0.3,4.4,2,4.3,4.7c-0.1,4.2-4.7,7.4-9.6,10.7c-4,2.7-8.1,5.6-10.1,9.2c-1.9,3.3-1,7.5,2.2,10.2
			c1.6,1.4,3.9,2.3,6.7,2.3c2.3,0,5-0.7,7.8-2.6c-0.4-0.3-0.7-0.7-1-1.2c-5.3,3.4-10,2.4-12.6,0.2c-2.6-2.2-3.4-5.5-1.8-8.3
			C111.3,23,115.2,20.3,119,17.7z"/>
	</g>
	<path d="M115.2,4.8c-1.3,0-2.4,1.1-2.4,2.4s1.1,2.4,2.4,2.4s2.4-1.1,2.4-2.4S116.5,4.8,115.2,4.8z"/>
</g>
<g>
	<path d="M129.8,41l-1.3-2.7l1.4-0.6l1.3,2.7L129.8,41z M127.8,36.4l-1.3-2.7l1.4-0.6l1.3,2.7L127.8,36.4z M125.7,31.9l-1.3-2.7
		l1.4-0.6l1.3,2.7L125.7,31.9z M123.6,27.4l-1.3-2.7l1.4-0.6l1.3,2.7L123.6,27.4z M121.5,22.8l-1.3-2.7l1.4-0.6l1.3,2.7L121.5,22.8z
		 M119.4,18.3l-1.3-2.7l1.4-0.6l1.3,2.7L119.4,18.3z M117.3,13.7l-1.3-2.7l1.4-0.6l1.3,2.7L117.3,13.7z"/>
</g>
</svg>)
}